header,
footer {
  display: flex;
  position: sticky;
  flex-shrink: 0;
  /* Prevents header and footer from shrinking */
  background-color: #2C3138;
  color: white;

  /* Add a background color if desired */
}

header {
  top: 0;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
footer{
  width: 100%;
  bottom: 0;
  height: 8%;
}

.body-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.navigation-items .navigation-link {

  background-color: #2C3138;
  /* Change this to your preferred color */
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.navigation-items .nav-link:hover {
  background-color: #2c313886;
  /* Darker shade for hover */
}

.navigation-items div {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.navigation-items h4 {
  margin-top: 10px;
}
.left-navigation {
  width: 30%;
  height: 86%;
  position: fixed;
  top: 1;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 1;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding: 20px;
  transform: translateX(-100%);
  /* Start off the screen */
  transition: transform 0.3s ease-in-out;
  /* Animation effect */

}
.left-navigation.open {
  width: 30%;
  transform: translateX(0);
  /* Bring back on the screen */

}
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}
.auth-container {
  position: relative;
  
}

.login-toggle-btn {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; /* Adjust space between the form and button */
}

.details-panel{
  width: 30%;
  height: 85%;
  position: fixed;
  top: 1;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 1;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
  padding: 20px;
  transform: translateX(100%);
  /* Start off the screen */
  transition: transform 0.3s ease-in;
  /* Animation effect */
}
.details-panel.open{
  width: 30%;
  transform: translateX(0);
}
.details-panel.close{
  width: 30%;
  transform: translateX(100%);
}

@media (max-width: 767px) {
  .left-navigation {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 1000;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding: 20px;
    transform: translateX(-100%);
    /* Start off the screen */
    transition: transform 0.3s ease-in-out;
    /* Animation effect */

  }
  .left-navigation.open {
    width: 100%;
    transform: translateX(0);
    /* Bring back on the screen */

  }
  .details-panel {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 1000;
    overflow-y: scroll;
    border-right: 1px solid #ccc;
    padding: 20px;
    transform: translateX(100%);
    /* Start off the screen */
    transition: transform 0.3s ease-in-out;
    /* Animation effect */

  }
  .details-panel.open {
    width: 100%;
    transform: translateX(0);
    /* Bring back on the screen */

  }
  .details-panel.close {
    width: 100%;
    transform: translateX(100%);
    /* Bring back on the screen */

  }
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; /* Added to allow the centered element to take available space */
}
