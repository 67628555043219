/* InstallationPrompt.css */
.installation-prompt {
    padding: 20px;
    margin: 30px auto;
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .installation-prompt h1 {
    color: #333;
  }
  
  .installation-prompt p {
    color: #666;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .installation-prompt ol {
    text-align: left;
    margin: 20px auto 30px;
    padding-left: 40px;
  }
  
  .installation-prompt img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
  /* iOS-style App Icon */

  
  .app-icon img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove any extra space below the image */
    width: 80px;       /* Size of the icon */
    height: 80px;      /* Size of the icon */
    border-radius: 14px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
    margin: 20px auto; /* Center the icon */
    background-color: #f4f4f4; /* Light grey background, similar to iOS */
    display: block; /* To apply margin auto correctly */
    overflow: hidden;
    
  }
  
  .app-icon-small img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    width: 50px;       /* Size of the icon */
    height: 50px;      /* Size of the icon */
    display: block; /* To apply margin auto correctly */
    
  }
  